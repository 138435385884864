import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import StoresPage from "../components/StoresPage"

const StoresArchivePage = ({ data, location }) => {

    const localeID = 'en_US';

    return (
        <Layout location={location} localeID={localeID} navShadow={true}>
            <Seo title="Stores" />
            <StoresPage localeID={localeID} data={data}/>
        </Layout>
    )
  }
  
export default StoresArchivePage

export const pageQuery = graphql`
    query StoresArchivePageEN {
        allStores: allWpPost(
            filter: {categories: {nodes: {elemMatch: {slug: {eq: "stores"}}}}, locale: {locale: {eq: "en_US"}}}
        ) {
            ...StoreSliderData
        }
        cuisineWorld: allWpPost(
            filter: {categories: {nodes: {elemMatch: {slug: {eq: "world-cuisines"}}}}, locale: {locale: {eq: "en_US"}}}
        ) {
            ...StoreSliderData
        }
        vegetarian: allWpPost(
            filter: {categories: {nodes: {elemMatch: {slug: {eq: "vegetarian"}}}}, locale: {locale: {eq: "en_US"}}}
        ) {
            ...StoreSliderData
        }
        farmers: allWpPost(
            filter: {categories: {nodes: {elemMatch: {slug: {eq: "farmers-market"}}}}, locale: {locale: {eq: "en_US"}}}
        ) {
            ...StoreSliderData
        }
        vegetable: allWpPost(
            filter: {categories: {nodes: {elemMatch: {slug: {eq: "fruits-vegetables"}}}}, locale: {locale: {eq: "en_US"}}}
        ) {
            ...StoreSliderData
        }
        bakery: allWpPost(
            filter: {categories: {nodes: {elemMatch: {slug: {eq: "bakery-confectionery"}}}}, locale: {locale: {eq: "en_US"}}}
        ) {
            ...StoreSliderData
        }
        foreign: allWpPost(
            filter: {categories: {nodes: {elemMatch: {slug: {eq: "foreign-specialties"}}}}, locale: {locale: {eq: "en_US"}}}
        ) {
            ...StoreSliderData
        }
        ekoGro: allWpPost(
            filter: {categories: {nodes: {elemMatch: {slug: {eq: "organic-food"}}}}, locale: {locale: {eq: "en_US"}}}
        ) {
            ...StoreSliderData
        }
        books: allWpPost(
            filter: {categories: {nodes: {elemMatch: {slug: {eq: "flowers-books"}}}}, locale: {locale: {eq: "en_US"}}}
        ) {
            ...StoreSliderData
        }
        alcohol: allWpPost(
            filter: {categories: {nodes: {elemMatch: {slug: {eq: "alcoholic-specialties"}}}}, locale: {locale: {eq: "en_US"}}}
        ) {
            ...StoreSliderData
        }
        meatFish: allWpPost(
            filter: {categories: {nodes: {elemMatch: {slug: {eq: "meat-fish"}}}}, locale: {locale: {eq: "en_US"}}}
        ) {
            ...StoreSliderData
        }
        cheese: allWpPost(
            filter: {categories: {nodes: {elemMatch: {slug: {eq: "cheese-specialties"}}}}, locale: {locale: {eq: "en_US"}}}
        ) {
            ...StoreSliderData
        }
        bars: allWpPost(
            filter: {categories: {nodes: {elemMatch: {slug: {eq: "cafes-bars"}}}}, locale: {locale: {eq: "en_US"}}}
        ) {
            ...StoreSliderData
        }
        tags: allWpTag(filter: {slug: {regex: "/^en/"}}) {
            nodes {
                name
                description
                slug
            }
        }
    }
`